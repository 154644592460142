<template>
    <NavbarHome1/>
    <Breadcrumb :mainTitle="service.name" :subTitle="'Services / ' + service.name" />
    <!-- Service Details -->

    <div id="service-page" class="service-details-section section-padding pb-0">
        <div class="container">
            <div class="row">
                <div class="col-lg-4">
                    <img :src="images.banner_image" alt="banner image">
                    <div class="service-list">
                        <h5>Service List</h5>
                        <router-link :class="this.$route.params.id == service.id ? 'active' : ''" :to="{name:'ServiceDetails', params:{ id: service.id } }" v-for="service in services" :key="service.id" @click="reRenderData">{{service.name}}<span><i class="las la-arrow-right"></i></span></router-link>
                       

                    </div>
<br><br><br><br><br><br><br>
                  <!--  <div class="evalution-form">
                        <h6>Have any Question?</h6>
                        <form action="form.php" method="post">
    <div class="row">
        <div class="col-lg-12 col-md-12 col-12">
            <input type="text" name="name" placeholder="Your Name">
        </div>
        <div class="col-lg-12 col-md-12 col-12">
            <input type="email" name="email" placeholder="Your E-mail">
        </div>
        <div class="col-lg-12 col-md-12 col-12">
            <input type="tel" name="phone" placeholder="Your Phone Number">
        </div>
        <div class="col-lg-12 col-md-12 col-12">
            <input type="text" name="subject" placeholder="Subject">
        </div>
        <div class="col-lg-12 col-md-12 col-12">
            <select name="service" required>
                <option value="" disabled selected>Select service required</option>
                <option value="General services">General Services</option>
                <option value="Complex Fraud">Complex Fraud</option>
                <option value="Personal Injury">Personal Injury</option>
                <option value="Civil Litigation">Civil Litigation</option>
                <option value="Medical Negligence">Medical Negligence</option>
                <option value="Sexual Offences">Sexual Offences</option>
                <option value="Criminal Law">Criminal Law</option>
                <option value="Motoring Offences">Motoring Offences</option>
                <option value="Motor Finance Claims">Motor Finance Claims</option>
                <option value="Immigration">Immigration</option>
            </select>
        </div>
        <div class="col-lg-12 col-md-12 col-12">
            <textarea name="message" cols="30" rows="10" placeholder="Your Message"></textarea>
        </div>
        <div class="col-lg-12 col-md-12 col-12">
            <input class="main-btn" type="submit" value="Submit Now">
        </div>
    </div>
</form>

                    </div>-->

                </div>

                <div class="col-lg-8">
                    <div class="single-service">
                        <h2>{{service.name}}</h2>

                        <div v-if="service.id === '1'">
                            <img src="/assets/img/badges/legalaidavailable.png" width="100px">
                        <p>Fraud is an act whereby someone gains a dishonest advantage, often financial, over another person.  In England and Wales, fraud is the most commonly experienced crime with an estimated 3.4 million incidents in the year ending March 2017.
<br><br>
We understand that things are not as always as they seem and that you may have unknowingly or unwittingly become involved in fraud.  We have vast experience in this subject.  Listed below are just some of the areas we have dealt with:</p>
<br>
<div style="float: left; width: 50%;">
    <p style="font-family: inherit; font-size: inherit;">
- Serious VAT fraud<br>
- Regulatory Investigations<br>
- Commodity trading and compliance<br>
- Financial Services Investigations and Regulations
</p>
        </div>
        <div style="float: left; width: 50%;">
            <p style="font-family: inherit; font-size: inherit;">
- Banking Fraud<br>
- Confiscation, restraint and forfeiture of assets and cash proceedings<br>
- Money laundering investigations<br>
- Forfeiture of assets
</p>
        </div>
        <br>
        <img src="/assets/img/badges/wereonyoursidedark.png" width="200px">
<br><br>
                        <hr>
                        <!--<div class="row">
                            <div class="col-lg-6 col-md-6 col-12" v-for="feature in service.features" :key="feature.id">
                                <div class="key-feature text-center pr-50">
                                    <div class="row justify-content-center no-gutters">
                                        <div class="col-lg-4">
                                            <div class="about-icon">
                                                <img :src="feature.icon" alt="">
                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <h5>{{feature.title}}</h5>
                                            <p>{{feature.description}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>                         
                        </div>
                        
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="single-service-bg mt-50 pb-20">
                                    <img :src="images.sub_image_1" alt="sub image">
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="single-service-bg mt-50 pb-20">
                                    <img :src="images.sub_image_2" alt="sub image">
                                </div>
                            </div>
                        </div>

                        <h5>{{service.sub_heading_2}}</h5>
                        <p>{{service.sub_description_2}}</p>
                       
                        <hr>-->

                        <br>
                     </div>

                     <div v-if="service.id === '2'">
                        <img src="/assets/img/badges/legalaidavailable.png" width="100px">
    <p><b>Representation/Advice At Magistrates Court (Or Crown Court)</b><br><br>
    Need representation at Court?<br><br>
    Every person has the right to a fair trial. At Good & Co, we can guarantee you the best defence. Our Criminal Law team are here to help and advise you through an often difficult and stressful ordeal.  We have extensive experience and great success rates in representing clients who have been accused of the following, (although this is not an exhaustive list):</p><br>
    <div style="float: left; width: 50%;">
        <p style="font-family: inherit; font-size: inherit;">
            - Murder<br>
            - Attempted Murder<br>
            - Manslaughter<br>
            - Blackmail<br>
            - Firearms Offences<br>
            - Armed Robberies<br>
        </p>
    </div>
    <div style="float: left; width: 50%;">
        <p style="font-family: inherit; font-size: inherit;">
            - Conspiracy to Supply Drugs<br>
            - Possession with Intent to Supply Controlled Drugs<br>
            - Serious Assault<br>
            - Kidnapping<br>
            - Burglary<br>
            - Robbery
        </p>
    </div><br><br>
    <p><b>Advice At Police Station</b><br>
    Under Arrest?<br><br>
    If arrested, every person is entitled to free advice at the police station.  Should you find yourself in this unfortunate situation, then ask for a legal representative from Good & Co, to ensure that you receive the best help available.  Our Criminal Law specialist will be able to offer you immediate advice on the telephone, no matter what time of the day or night.  They will then attend the police station to offer you the assistance you require as you are being interviewed by the police.  We have an emergency 24- hour helpline number on 07870 218 072.<br><br>
    If any other situation arises whereby you are subject to a criminal investigation, then we are here to help.  We understand that this can be a traumatic time, so contact Good & Co to ensure you get the best help and assistance.</p>
    <br>
        <img src="/assets/img/badges/wereonyoursidedark.png" width="200px">
    <hr>
                        <!--<div class="row">
                            <div class="col-lg-6 col-md-6 col-12" v-for="feature in service.features" :key="feature.id">
                                <div class="key-feature text-center pr-50">
                                    <div class="row justify-content-center no-gutters">
                                        <div class="col-lg-4">
                                            <div class="about-icon">
                                                <img :src="feature.icon" alt="">
                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <h5>{{feature.title}}</h5>
                                            <p>{{feature.description}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>                         
                        </div>
                        
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="single-service-bg mt-50 pb-20">
                                    <img :src="images.sub_image_1" alt="sub image">
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="single-service-bg mt-50 pb-20">
                                    <img :src="images.sub_image_2" alt="sub image">
                                </div>
                            </div>
                        </div>

                        <h5>{{service.sub_heading_2}}</h5>
                        <p>{{service.sub_description_2}}</p>
                       
                        <hr>-->

                        <br>
                     </div>

                     <div v-if="service.id === '3'">
                        <img src="/assets/img/badges/legalaidavailable.png" width="100px">
                        <p>Undoubtedly, this is a subject that arouses universal contempt. Those accused of such offences not only have to deal with the trauma and stress of trying to prove their innocence, but also the communal backlash that often occurs prior to a trial of any of the allegations.
<br><br>
At Good & Co, we believe that it is important to seek legal advice and assistance at an early stage. In fact, many of our clients have avoided charges altogether as a result of our early involvement.  We will ensure that you receive the best advice and our team of specialists will work relentlessly to ensure that you receive the best defence.
<br><br>
We have represented clients accused of the following, (although the list is not exhaustive):</p>
<br>
<div style="float: left; width: 50%;">
    <p style="font-family: inherit; font-size: inherit;">
- Historic sexual abuse<br>
- Trafficking/Child Sexual exploitation<br>
- Grooming<br>
- Child abuse<br>
- Rape<br>
- Sexual/Indecent assault<br>
- Making/possession of indecent images of children
</p>
        </div>
        <div style="float: left; width: 50%;">
            <p style="font-family: inherit; font-size: inherit;">
- Publication of private images/movies (“Revenge Porn”)<br>
- Malicious communications<br>
- Sexual Offences Prevention Orders (SOPOs)<br>
- Sexual Risk Orders (SROs)<br>
- Sexual Harm Prevention Orders (SHPOs)<br>
- other issues relating to the Sex Offenders Register
</p>
        </div>
        <br>
        <img src="/assets/img/badges/wereonyoursidedark.png" width="200px">
        <br><br>
                        <hr>
                        <!--<div class="row">
                            <div class="col-lg-6 col-md-6 col-12" v-for="feature in service.features" :key="feature.id">
                                <div class="key-feature text-center pr-50">
                                    <div class="row justify-content-center no-gutters">
                                        <div class="col-lg-4">
                                            <div class="about-icon">
                                                <img :src="feature.icon" alt="">
                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <h5>{{feature.title}}</h5>
                                            <p>{{feature.description}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>                         
                        </div>
                        
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="single-service-bg mt-50 pb-20">
                                    <img :src="images.sub_image_1" alt="sub image">
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="single-service-bg mt-50 pb-20">
                                    <img :src="images.sub_image_2" alt="sub image">
                                </div>
                            </div>
                        </div>

                        <h5>{{service.sub_heading_2}}</h5>
                        <p>{{service.sub_description_2}}</p>
                       
                        <hr>-->

                        <br>
                     </div>

                     <div v-if="service.id === '4'">
                        <img src="/assets/img/badges/nowinnofee.png" width="100px">
                        <p>Every year, over 3 million people are injured in accidents.  If you are not to blame, then you have a right to compensation.  Even if you are partly to blame, you may still have a right to compensation.
<br><br>
At Good & Co solicitors, we have extensive experience in advising clients who have been injured – whether it is at work, in their cars, outdoors or at home.  This area of the law is complicated, however we will ensure that you understand the whole process as we lead you through it from beginning to end.
<br><br>
We will deal with third parties on your behalf, such as insurance companies, medical professionals and car hire firms.  We already have an extensive list of contacts that will ensure your case runs smoothly, so that you can concentrate on your recovery.
<br><br>
Should your case go to court, we will ensure that thorough preparation is done and competent experts appointed so that you get the best possible outcome.
<br><br>
We know that clients often worry about the cost and possible deductions from their compensation.  At Good & Co, we offer a “no-win, no-fee” agreement to ensure that you are able to pursue your claim.  Also, we will provide you with clear and detailed information on all costs, so you know what to expect before we even begin your claim.
<br><br>
We have represented clients who have been involved in the following, (although the list is not exhaustive):</p>
<br>
<div style="float: left; width: 50%;">
  <p style="font-family: inherit; font-size: inherit;">
    - Road Traffic Accidents (Driver or Passenger)<br>
    - Slip and Fall Accidents<br>
    - Workplace Injuries<br>
    - Medical Negligence Claims
  </p>
</div>
<div style="float: left; width: 50%;">
  <p style="font-family: inherit; font-size: inherit;">
    - Defective Products<br>
    - Clinical Negligence<br>
    - Holiday Sickness Claims
  </p>
</div>
<br>
        <img src="/assets/img/badges/wereonyoursidedark.png" width="200px">
<br><br>

                        <hr>
                        <!--<div class="row">
                            <div class="col-lg-6 col-md-6 col-12" v-for="feature in service.features" :key="feature.id">
                                <div class="key-feature text-center pr-50">
                                    <div class="row justify-content-center no-gutters">
                                        <div class="col-lg-4">
                                            <div class="about-icon">
                                                <img :src="feature.icon" alt="">
                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <h5>{{feature.title}}</h5>
                                            <p>{{feature.description}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>                         
                        </div>
                        
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="single-service-bg mt-50 pb-20">
                                    <img :src="images.sub_image_1" alt="sub image">
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="single-service-bg mt-50 pb-20">
                                    <img :src="images.sub_image_2" alt="sub image">
                                </div>
                            </div>
                        </div>

                        <h5>{{service.sub_heading_2}}</h5>
                        <p>{{service.sub_description_2}}</p>
                       
                        <hr>-->

                        <br>
                     </div>

                     <div v-if="service.id === '5'">
                        <img src="/assets/img/badges/bestpriceguarantee.png" width="100px">
                        <p>No-one can underestimate the importance of driving in our daily lives.  However, with the ever-changing law in this area, it can seem increasingly difficult to stay informed so that the law is not broken.
<br><br>
Are you in the unfortunate situation of facing a road traffic offence?  Are you facing penalty points, disqualification or even imprisonment?  At Good & Co, we can offer you the advice and assistance you need.  We have experience of dealing with cases in the Youth, Magistrates, Crown and Appeal Courts.
<br><br>
We have represented clients accused of the following allegations, (although the list is not exhaustive):</p>
<br>
<div style="float: left; width: 50%;">
    <p style="font-family: inherit; font-size: inherit;">
- Careless Driving<br>
- Construction and Use<br>
- Dangerous Driving<br>
- Death by Dangerous Driving<br>
- Drink Driving<br>
- Driving Disqualification<br>
- Driver Identification<br>
- Driver’s Hours<br>
- Drunk in Charge<br>
- Failing to Stop/Report
</p>
        </div>
        <div style="float: left; width: 50%;">
            <p style="font-family: inherit; font-size: inherit;">
- Insurance<br>
- Speeding<br>
- Speed Camera/Radar<br>
- Tachographs<br>
- Taxi Drivers<br>
- Totting Up<br>
- Traffic Signs<br>
- Exceptional Hardship<br>
- Special Reasons
</p>
 <br><br>       </div>
 <br>
        <img src="/assets/img/badges/wereonyoursidedark.png" width="200px">
        <br>
        <br>
        <b>Motoring Defence Fees</b>
        <br><br>
<b>Our Fees</b>
<br><br>
Our Motoring Defence Team are always on hand to give advice and support to motorists who may find themselves in difficult situations, when being faced with complex motoring laws and regulations. Our expertise in the field of Motoring Law goes hand-in-hand with the care that we give our clients. Our Testimonials speak of the solid dependability that we give our clients, as we continuously strive for the very best outcome in their cases.
<br><br>
Our Team have been handling all aspects of motoring law for many years. This fact always gives our clients the peace of mind they seek; especially as this area of law is very intricate. Your case will always be given our full attention and dedication.
<br><br>
Upon instruction, all of our clients are given direct contact details for their solicitor, who will always endeavour to get back to them as soon as possible. Although there is no legal aid available for a majority of driving offences, if you are looking to instruct our team to work on your behalf, we can work out the best finance option for you. Here we list in a clear way, our fees and the costs involved with a typical Motoring Law case. Listed are the most common types of Motoring Law case, but if you cannot see a description that meets your exact requirements, please contact us and we’ll prepare a bespoke quote for you.

<br><br>

<b>Our Fixed Fees</b>
<br><br>
Initial Advice – £99
<br><br>
Contact us and get initial advice about your case. We review the information and discuss the various options with you. As part of this, we explain the possible defences and a likely sentence if pleading guilty. Afterwards, you will have a better understanding of where you stand and the best way forward in your case. Your 20 minute consultation with an experienced motor defence solicitor either takes place over the telephone or in one of our offices.

<br><br>

Written Guilty Plea – £500*
<br><br>
Includes the above; also the management of your case and bringing it to a conclusion by submission of a written guilty plea. This will additionally include preparing a letter in mitigation and communicating with the court on your behalf.
<br><br>
 

Court Appearance: Guilty Plea – £500 – £1,000*
<br><br>
As above; also the cost of representing you at court on a guilty plea, [assuming only one hearing] this will include meeting you at court and dealing with your case as a priority on the day. Includes our conference time of 1 hour.

 
<br><br>
Court Appearance: Exceptional Hardship Cases – £1,000 – £2,000*
<br><br>
Ordinarily anyone who receives 12 or more penalty points via ‘totting up’ procedures is disqualification from driving. However, it could be possible to avoid a disqualification by successfully arguing ‘exception hardship’ before the court. Our team of experts can provide expert representation in such cases. This includes a conference call as required and the preparation of written submissions to court to assist your evidence, attending upon you on the day at court and presenting your case to the court. We will advise you on your rights of appeal, as necessary.

<br><br>

Court Hearings: Special Reasons Argument- £1,000 – £3,000*
<br><br>
In certain road traffic matters it can be argued that despite an individuals guilt to the offence there are ‘special reasons’ whereby the subsequent penalty can be avoided altogether or reduced. Our team of experts can provide expert representation is such matters, which can be technical. Our services would include initial consultations, preparatory work for the hearing and representing you at the hearing.
<br><br>
 

Court Hearing: Not Guilty Trial – £1,000 – £5,000*
<br><br>
We are regularly instructed to challenge cases in trial. Typically these involve costs of £3,000 + VAT and the costs of any expert’s reports. In every trial case you would be provided with a detailed costs estimate. Please note that the above fees are legal fees and exclude disbursements for expert reports and medical records. Furthermore, these fees are purely for indicative purposes and are subject to a formal consultation and assessment of your case.

<br><br>

*Plus VAT.
                        <hr>
                        <!--<div class="row">
                            <div class="col-lg-6 col-md-6 col-12" v-for="feature in service.features" :key="feature.id">
                                <div class="key-feature text-center pr-50">
                                    <div class="row justify-content-center no-gutters">
                                        <div class="col-lg-4">
                                            <div class="about-icon">
                                                <img :src="feature.icon" alt="">
                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <h5>{{feature.title}}</h5>
                                            <p>{{feature.description}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>                         
                        </div>
                        
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="single-service-bg mt-50 pb-20">
                                    <img :src="images.sub_image_1" alt="sub image">
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="single-service-bg mt-50 pb-20">
                                    <img :src="images.sub_image_2" alt="sub image">
                                </div>
                            </div>
                        </div>

                        <h5>{{service.sub_heading_2}}</h5>
                        <p>{{service.sub_description_2}}</p>
                       
                        <hr>-->

                        <br>
                     </div>

                     <div v-if="service.id === '6'">
                        <img src="/assets/img/badges/nowinnofee.png" width="100px">
<p>Our financial services encompass a variety of solutions to address mis-selling issues. With a diverse range of financial products involved, our team ensures personalised strategies to meet your specific needs. Choosing our services means selecting a reliable partner committed to delivering results. We are highly skilled and professional, providing expertise in the field of financial mis-selling.</p>

<p>Features:
    <ul>
        <li><b>Expertise in Financial Law:</b> Our team possesses in-depth knowledge of financial law, ensuring a comprehensive understanding of regulations and rules.</li>
        <li><b>Advocates for Your Rights:</b> We fight passionately for justice, leveraging our extensive legal knowledge to advocate for your rights in financial mis-selling cases.</li>
    </ul>
</p>

At Good & Co Solicitors we offer:
    <ul>
        <b>Motor Finance:</b> If you believe you have been mis-sold a finance deal, you may be owed thousands. Even if you haven’t lost money, if the car finance deal isn’t right for you and your financial situation you can potentially bring forward a claim for PCP mis-selling. If you used PCP or HP to finance a vehicle between 2014 – 2019, you could be owed thousands.
                            <br><br>
                            What Are Mis-Sold Car Finance (PCP) Claims?
You may have been a victim of mis-sold car finance if you have received negligent or poor advice in relation to your car finance options in the last six years, or if you were not made aware of any commission being charged within the agreement. The lender might have been misleading about the extra costs involved, or they may have charged you more interest than they should have.
<br><br>
“Some motor dealers are overcharging unsuspecting customers over £1000 in interest charges in order to obtain bigger commission pay-outs for themselves. This is unacceptable”. Jonathan Davidson, director of supervision for retail and authorisations at the FCA.
                            <br><br>
                            What are the most common types of Mis-Sold Car Finance Claims?
Some of the main reasons you may have been mis-sold your PCP car finance deal are:
<br><br>
• Undisclosed or hidden commission
<br>
• High-pressure sales tactics
<br>
• Lack of proper affordability checks
<br>
• Inflated prices and overcharges in interest
<br>
• Emissions Scandal Claims<br><br>
        <li><b>Endowment Policies:</b> Legal assistance for individuals who have been mis-sold endowment policies, which are investment-based life insurance policies typically linked to mortgages.</li>
        <li><b>Investments:</b> Legal support for cases involving mis-selling of investment products, such as stocks, bonds, mutual funds, or other securities.</li>
        <li><b>Mortgage:</b> Assistance for individuals who have been improperly advised or misled in relation to mortgage agreements, including issues like interest rates, terms, or suitability.</li>
        <li><b>Packaged Bank Accounts:</b> Legal representation for those who have been sold packaged bank accounts with unnecessary or unsuitable features, leading to financial detriment.</li>
        <li><b>Self-Invested Personal Pensions:</b> Support for individuals who have suffered losses due to mis-selling or inappropriate recommendations regarding SIPPs, which are pension schemes allowing individuals to manage their investments.</li>
        <li><b>Brokered Utility Agreements:</b> Assistance for clients who have been misled or misinformed regarding utility agreements brokered by financial intermediaries, resulting in financial harm or unfair terms.</li>
    </ul>
    <br>
        <img src="/assets/img/badges/wereonyoursidedark.png" width="200px">

                        <hr>
                        <!--<div class="row">
                            <div class="col-lg-6 col-md-6 col-12" v-for="feature in service.features" :key="feature.id">
                                <div class="key-feature text-center pr-50">
                                    <div class="row justify-content-center no-gutters">
                                        <div class="col-lg-4">
                                            <div class="about-icon">
                                                <img :src="feature.icon" alt="">
                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <h5>{{feature.title}}</h5>
                                            <p>{{feature.description}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>                         
                        </div>
                        
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="single-service-bg mt-50 pb-20">
                                    <img :src="images.sub_image_1" alt="sub image">
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="single-service-bg mt-50 pb-20">
                                    <img :src="images.sub_image_2" alt="sub image">
                                </div>
                            </div>
                        </div>

                        <h5>{{service.sub_heading_2}}</h5>
                        <p>{{service.sub_description_2}}</p>
                       
                        <hr>-->

                        <br>
                     </div>

                     <div v-if="service.id === '7'">
                        <img src="/assets/img/badges/bestpriceguarantee.png" width="100px">
                        <p>At Good & Co, we understand the intricacies of UK immigration law and its profound impact on individuals and families seeking to make the UK their new home. Our dedicated team of expert solicitors is committed to providing comprehensive and tailored legal assistance to help navigate the complexities of the immigration process.
                            <br><br>
                            Our services encompass a wide spectrum of immigration categories, including:
                            <br><br>
                            <b>Visas:</b> We advise on and assist with applications for all types of UK visas, including work, study, family, and visitor visas. We ensure our clients understand the specific requirements of each category and guide them through the process to maximise their chances of success.
                            <br><br>
                            <b>Asylum and Refugee Status:</b> We offer specialised legal advice and support to those seeking asylum or applying for refugee status in the UK. Our team is experienced in providing compassionate and effective representation in these often-sensitive cases.
                            <br><br>
                            <b>Permanent Residence and British Citizenship:</b> We guide clients through the process of applying for Indefinite Leave to Remain (ILR), ensuring that they meet all the requirements. We also assist clients in the naturalisation process to obtain British citizenship.
                            <br><br>
                            <b>Immigration Compliance and Corporate Immigration:</b> For businesses, we provide advice on immigration compliance and support with sponsorship licenses and employment of foreign nationals.
                            <br><br>
                            At Good & Co, we pride ourselves on our client-centred approach. We understand that every immigration case is unique, and we work closely with our clients to provide bespoke legal advice tailored to their individual circumstances and objectives.
                            <br><br>
                            Immigration law is complex and ever-changing. Our immigration solicitors stay abreast of the latest legislative developments and legal precedents to ensure we provide accurate, timely, and strategic advice. We strive to simplify the immigration process for our clients, minimising stress and maximising successful outcomes.
                        </p>
                        <br>
        <img src="/assets/img/badges/wereonyoursidedark.png" width="200px">

                        <hr>
                        <!--<div class="row">
                            <div class="col-lg-6 col-md-6 col-12" v-for="feature in service.features" :key="feature.id">
                                <div class="key-feature text-center pr-50">
                                    <div class="row justify-content-center no-gutters">
                                        <div class="col-lg-4">
                                            <div class="about-icon">
                                                <img :src="feature.icon" alt="">
                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <h5>{{feature.title}}</h5>
                                            <p>{{feature.description}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>                         
                        </div>
                        
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="single-service-bg mt-50 pb-20">
                                    <img :src="images.sub_image_1" alt="sub image">
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="single-service-bg mt-50 pb-20">
                                    <img :src="images.sub_image_2" alt="sub image">
                                </div>
                            </div>
                        </div>

                        <h5>{{service.sub_heading_2}}</h5>
                        <p>{{service.sub_description_2}}</p>
                       
                        <hr>-->

                        <br>
                     </div>

                     <div v-if="service.id === '8'">
                        <p>Civil litigation involves disputes with a person or an organisation.  It can entail small and simple claims or a large claim between multi-national companies.  This can typically include personal injury claims; breach of contract claims; housing disputes; bankruptcy; damaged goods claims and recovery of debt, to mention a few.
<br><br>
Civil litigation is mainly dealt with by the County Courts, although more substantial and complex cases are dealt with by the High Court.  Every year about 1.4 million civil claims are brought to County Courts.  Typically only about 3-4% require a hearing, as either the Defendant does not turn up or a settlement is reached.
<br><br>
Although the civil courts do not have jurisdiction to imprison a person, they can award damages to a successful party.
<br><br>
If you believe that you may have a civil claim, or are subject to such a claim, we can help you.
</p>
<hr>    
                        <!--<div class="row">
                            <div class="col-lg-6 col-md-6 col-12" v-for="feature in service.features" :key="feature.id">
                                <div class="key-feature text-center pr-50">
                                    <div class="row justify-content-center no-gutters">
                                        <div class="col-lg-4">
                                            <div class="about-icon">
                                                <img :src="feature.icon" alt="">
                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <h5>{{feature.title}}</h5>
                                            <p>{{feature.description}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>                         
                        </div>
                        
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="single-service-bg mt-50 pb-20">
                                    <img :src="images.sub_image_1" alt="sub image">
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="single-service-bg mt-50 pb-20">
                                    <img :src="images.sub_image_2" alt="sub image">
                                </div>
                            </div>
                        </div>

                        <h5>{{service.sub_heading_2}}</h5>
                        <p>{{service.sub_description_2}}</p>
                       
                        <hr>-->

                        <br>
                     </div>

                        <div v-if="service.id === '9'">
                        <h5>Court of Protection/Deputyship Services</h5>
                        <p>Should you find yourself in a situation where you lose capacity to make your own decisions, then it may become necessary to apply to the Court of Protection for the appointment of a Deputy.  This will not be a necessity if you have already made a Lasting or Enduring Power of Attorney.
<br><br>
A Financial Deputy’s role is to be responsible for the management of a person’s financial affairs in the event that they become incapable. Incapacity could be the result of clinical negligence, a brain injury or dementia.
<br><br>
There are two types of Deputy – property and financial affairs Deputy (dealing with financial matters) or personal welfare Deputy (dealing with social and welfare matters).  The Court of Protection is a specialist Court responsible for appointing and overseeing the work of a Deputy.
<br><br>
Good & Co Solicitors can provide Deputyship Services to Clients who lack the capacity to deal with some or all of their finances. We also have a Court of Protection approved Deputy who can assist.</p>
                        <hr>
                        <h5>Statutory Declarations</h5>
                        <p>Statutory Declarations are formal statements, made in a prescribed way, affirming that something is true to the best knowledge of the person making the declaration. Effectively, they satisfy a legal requirement or regulation in instances where other such evidence is unavailable.
<br><br>
Statutory Declarations are legal documents that are governed by the Statutory Declarations Act 1835 and must contain certain prescribed wording to be valid.
<br><br>
At Good & Co Solicitors we are able to assist you in drafting or witnessing any Statutory Declaration according to your individual circumstances.</p>
                        <hr>
                        <h5>Wills</h5>
                        <p>We understand the importance of planning ahead to preserve your assets and mitigate tax liabilities.  All our clients are keen to exercise as much choice and control as possible over their financial affairs.  This is why it is essential to ensure that you have a valid will.
<br><br>
At Good & Co we are able to assist you in drafting your will or in providing general probate advice.  Our primary aim is to safeguard your interests and to make any bequests as tax-efficient as possible.  We are also experienced in the area of Sharia-compliant wills for those clients who require this service.</p>
<br>
        <img src="/assets/img/badges/wereonyoursidedark.png" width="200px">                       
<hr>
                        <!--<h5>{{service.sub_heading_1}}</h5>
                        <p>{{service.sub_description_1}}</p>
                        <div class="row">
                            <div class="col-lg-6 col-md-6 col-12" v-for="feature in service.features" :key="feature.id">
                                <div class="key-feature text-center pr-50">
                                    <div class="row justify-content-center no-gutters">
                                        <div class="col-lg-4">
                                            <div class="about-icon">
                                                <img :src="feature.icon" alt="">
                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <h5>{{feature.title}}</h5>
                                            <p>{{feature.description}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>                         
                        </div>
                        
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="single-service-bg mt-50 pb-20">
                                    <img :src="images.sub_image_1" alt="sub image">
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="single-service-bg mt-50 pb-20">
                                    <img :src="images.sub_image_2" alt="sub image">
                                </div>
                            </div>
                        </div>

                        <h5>{{service.sub_heading_2}}</h5>
                        <p>{{service.sub_description_2}}</p>
                       
                        <hr>-->

                        <br>
                     </div>

                     <div class="helpline-section">
                        <div class="helpline-content text-     
                        c                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                             center">
                            <h4>Need Help?</h4>
                            <p>Get in touch with us for a no obligation consultation today!</p>
                            <button onclick="location.href='/contact'" class="btn btn-primary" type="submit">Contact Us</button>
                        </div>
                    </div>

                    </div>
                </div>
            </div>
        </div>
    </div>

    <!--CTA Section-->

    <CTA/>

 
    
</template>

<script>
// @ is an alias to /src
import NavbarHome1 from '@/components/NavbarHome1.vue'
import Breadcrumb from '@/components/Breadcrumb.vue'
import CTA from '@/components/CTA.vue'

import axios from 'axios'
export default {
    name: 'SingleService',
    components : {NavbarHome1, Breadcrumb,CTA},
    data(){
        return {
            services : [],
            service : [],
            images : [],
        }
    },
    
    async mounted(){
        //scrolling to top of the window
        window.scrollTo(0, 0);          
        
        let fetchedData = await axios.get('/assets/data/services.json');
        this.services = fetchedData.data.services;
        
        this.service = this.services.find( (item) => item.id == this.$route.params.id);
        this.images = this.service.images;
        
    },

    methods:{
       

        async reRenderData(){
            let fetchedData = await axios.get('/assets/data/services.json');
            this.services = fetchedData.data.services;

            this.service = this.services.find( (item) => item.id == this.$route.params.id);
            this.images = this.service.images;                  
        }
    },
}
</script>