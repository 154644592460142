<template>
    <NavbarHome1/>
    <Breadcrumb mainTitle="Sign In" subTitle="Sign In"/>
    <!-- Sign In  -->

    <div class="main-wrapper gray-bg">
        <div class="image-holder sign-in">
            <img src="/assets/img/sign-in.jpg" alt="">
        </div>
        <div class="form-inner sign-in">
            <form action="">
                <div class="form-header">
                    <h3>Sign In</h3>
                    <hr>
                </div>                
                <div class="form-group">
                    <label for="">E-mail</label>
                    <input type="email" class="form-control">
                </div>
                <div class="form-group">
                    <label for="">Password</label>
                    <input type="password" class="form-control">
                </div>
                                
                <div class="form-group">
                    <a href="index.html" class="main-btn">Log In</a>
                </div>
                
                <p class="form-meta mt-30">
                    <a class="text-link" href="#">Forgot your password</a>                    
                </p>
                <p class="form-meta">
                    <a class="text-link" href="registration.html">Create Account</a>                    
                </p>               
            </form>
        </div>
    </div>
        
</template>

<script>
// @ is an alias to /src
import NavbarHome1 from '@/components/NavbarHome1.vue'
import Breadcrumb from '@/components/Breadcrumb.vue'

export default {
    name: 'SignIn',
    components : {NavbarHome1,  Breadcrumb},
    data(){
        return{ }
    },
    mounted(){
        //scrolling to top of the window
        window.scrollTo(0, 0);        
    }

   
}
</script>