<template>
    <NavbarHome1/>

    <!-- Hero Area -->
	<!-- <video autoplay loop muted playsinline width="100%" height="100%" style="object-fit: cover;">
                <source src="/assets/img/banner.mp4" type="video/mp4">
                Your browser does not support the video tag.
            </video> -->

	<div class="homepage-slides">
        <swiper
			:modules="modules"
			:slides-per-view="1"
			:space-between="0"
			navigation
			:pagination="{ clickable: true }"
			:autoplay="{ delay: 4000 }"
		>   <swiper-slide>
                <div class="single-slide-item">
                    <div class="hero-area-content">
                        <div class="container">
                            <div class="row justify-content-center">
                                <div class="col-lg-12 wow fadeInUp animated" data-wow-delay=".3s">
                                    <div class="section-title">
                                        <h1>Your Legal Solutions <br> <b>Start Here</b></h1>
                                        <p> Begin your legal journey with confidence, as our wide range of solutions <b>pave the way for your success.</b></p>
                                    </div>
                                    <router-link :to="{name:'About'}" class="main-btn transparent">Learn More</router-link>
                                    <router-link :to="{name:'Services'}" class="main-btn">View Services</router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </swiper-slide>

            <swiper-slide>
                <div class="single-slide-item hero-area-bg-2">			
                    <div class="hero-area-content">
                        <div class="container">
                            <div class="row justify-content-center">
                                <div class="col-lg-12 wow fadeInUp animated" data-wow-delay=".3s">
                                    <div class="section-title">
                                        <h1><b>Experience Excellence</b> <br> in Legal Services </h1>
                                        <p> <b>Elevate your legal experience</b> with our exceptional UK legal services, delivering excellence at every step.</p>
                                    </div>
                                     <router-link :to="{name:'About'}" class="main-btn transparent">Learn More</router-link>
									 <router-link :to="{name:'Services'}" class="main-btn">View Services</router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </swiper-slide>
        </swiper>
	</div>

	<!-- About Section-->

	<div class="about-area section-padding pb-50">
		<div class="container-fluid">
			<div class="row">
				<div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 wow fadeInLeft" data-wow-delay=".3s">
					<div class="about-img mb-50">
						<img src="assets/img/about/about-1.jpg" alt="">
						<div class="founded-year">
							<i class="flaticon-gavel">Founded in 2008</i>
						</div>
					</div>
				</div>
				<div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 wow fadeInUp" data-wow-delay=".4s">
					<div class="about-content-area">
						<div class="section-title">
							<h6>Introduction</h6>
							<h2>Established in 2008 - Your Trusted Law Firm for <b>Exceptional</b> Legal Solutions</h2>
						</div>
						<p>Good & Co solicitors are your one-stop law firm based in Bradford, with offices in Leeds, West Yorkshire. We specialise in Complex Fraud Offences; Criminal Law; Sexual Offences; Personal Injury; Motoring Offences; Financial Mis-selling; Immigration and Civil Litigation.</p>
						<p class="highlight">Established in 2008.</p>
						<p class="highlight">Committed to achieving the highest success rate possible.</p>
						<p class="highlight">Specialising in a wide range of practice areas.</p>
						<div class="founded">
							<div class="row no-gutters">
								<div class="col-lg-12 col-md-12 col-12">
									<div class="founded-text">
										<p>We have 15+ years Experience in Legal Services.</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<!-- Services Section -->

	<div class="services-area bg-cover section-padding pb-50">
        <div class="container">
            <div class="row">
                <div class="offset-lg-2 col-lg-8 text-center">
                    <div class="section-title">
                        <h6>Practice Areas</h6>
                        <h2>We Provide Various <br><b>Legal Services</b></h2>
                        <p class="text-white">We provide a diverse range of legal services. Our law practice utilises <br> cutting-edge techniques and modern approaches.</p>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-4 col-md-6 col-sm-12 wow fadeInLeft" data-wow-delay=".2s" v-for="service in services" :key="service.id" >
                    <div class="single-service-area mb-70">
						<a :href="'/service-details/' + service.id">
						<div class="service-icon">
                            <i :class="service.icon"></i>
                        </div>
                        <h4>{{service.name}}</h4>
                        <p>{{service.short_description}}</p>
					    </a>
                    </div>
                </div>
            </div>
        </div>
    </div>

	<!-- Case Studies -->

	<!--<div class="case-area section-padding">
		<div class="container">
			<div class="offset-lg-1 col-lg-10 text-center">
				<div class="section-title">
					<h6>Featured Work</h6>
					<h2>Recent <b>Case Studies</b></h2>
					<p>Law Practice equipment modern technique sit amet, consectetur adipisicing elit. <br> Repellendus laboriosam omnis cum.</p>
				</div>
			</div>
			<div class="row">

				<div class="col-lg-4 col-md-6 col-12 wow fadeInLeft" data-wow-delay=".2s" v-for="c in cases" :key="c.id">
					<div class="single-case-item mb-50 bg-cover" :style="'background-image:url('+c.images.bg_image+')'">
						<div class="case-inner">
							<div class="hover-info">
								<h6>{{c.category}}</h6>
								<h4>{{c.short_title}}</h4>
							</div>
							<router-link class="case-icon" :to="{name:'CaseDetails', params:{ id: c.id} }">
								<i class="las la-plus"></i>
							</router-link>
							
							
						</div>
					</div>
				</div>				
			</div>
		</div>
	</div>-->

	<!-- Feature Section-->

	<div class="feature-area bg-cover sky-bg section-padding">
		<div class="container">
			<div class="row">
				<div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 wow fadeInLeft" data-wow-delay=".3s">
					<div class="section-title">
						<h6>A Little Bit More</h6>
						<h2> We bring justification against <b>injustice</b></h2>
					</div>
					<div class="single-feature-item" v-for="reason in choose_us.reasons" :key="reason.id">
						<div class="feature-icon">
							<i :class="reason.icon"></i>
						</div>
						<div class="feature-content">
							<h5>{{reason.title}}</h5>
							<p>{{reason.short_description}}</p>
						</div>
					</div>
					
					
				</div>
				<div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 wow fadeInUp" data-wow-delay=".4s">
					<div class="feature-image">
						<img :src="choose_us.feature_image" alt="">
					</div>
				</div>
			</div>
		</div>
	</div>

	<!--CTA Section-->

	<div class="cta-area bg-cover">
  <div class="overlay-2"></div>
  <div class="container">
    <div class="row">
      <div class="col-lg-5 col-md-12 col-12 wow fadeInUp" data-wow-delay=".3s">
        <div class="evalution-form">
          <h5>Free Case Evalution</h5>
          <form action="form.php" method="post">
            <input type="text" name="name" placeholder="Your Name">
            <input type="email" name="email" placeholder="Your E-mail">
            <input type="tel" name="phone" placeholder="Your Phone Number">
            <input type="text" name="subject" placeholder="Subject">
			<select name="service" required>
				<option value="" disabled selected>Select service required</option>
                <option value="General services">General Services</option>
                <option value="Complex Fraud">Complex Fraud</option>
                <option value="Personal Injury">Personal Injury</option>
                <option value="Civil Litigation">Civil Litigation</option>
                <option value="Medical Negligence">Medical Negligence</option>
                <option value="Sexual Offences">Sexual Offences</option>
                <option value="Criminal Law">Criminal Law</option>
                <option value="Motoring Offences">Motoring Offences</option>
                <option value="Motor Finance Claims">Motor Finance Claims</option>
                <option value="Immigration">Immigration</option>
            </select>
            <textarea name="message" cols="30" rows="10" placeholder="Your Message"></textarea>
            <input class="main-btn" type="submit" value="Submit Now">
          </form>
        </div>
      </div>
      <div class="offset-lg-1 col-lg-6 col-md-12 col-12 wow fadeInDown" data-wow-delay=".4s">
        <div class="section-title mt-50">
          <h6>We Are Here</h6>
          <h2>Get Prompt Support & Expert Legal Advice From an Expert <b>Solicitor</b></h2>
          <p>Get quick and reliable legal advice from our dedicated team. Contact us for assistance, we reply within 24 hours!</p>
          <h5>Contact Us</h5>
          <p class="tel">01274 494498</p>
        </div>
      </div>
    </div>
  </div>
</div>

	<!-- Team Section-->

	    <!--<div class="team-area section-padding pb-150">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-12">
                    <div class="section-title text-center">
                        <h6>Meet Our Lawyer</h6>
                        <h2>Qualified <b>Attorneys</b></h2>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-3 col-md-6 col-sm-6 col-12 wow fadeInLeft my-5 pb-5 " data-wow-delay=".2s" v-for="attorney in attorneys.slice(0,4)" :key="attorney.id">
					<div class="single-team-member">
						<div class="team-member-bg" :style="'background-image:url('+attorney.image+')'">
							<div class="team-content">
								<div class="team-title">
									
									<router-link :to="{name:'AttorneyDetails', params:{ id: attorney.id} }">{{attorney.name}}</router-link>
								</div>
								<div class="team-subtitle">
									<p>{{attorney.designation}}</p>
								</div>
							</div>
							<div class="team-social">
								<ul>
									<li><a href="#"><i class="lab la-facebook"></i></a></li>
									<li><a href="#"><i class="lab la-twitter"></i></a></li>
									<li><a href="#"><i class="lab la-linkedin"></i></a></li>
								</ul>
							</div>
						</div>

					</div>
				</div>

                

                

                
            </div>
        </div>
    </div>-->

	<!-- Testimonial Section -->

	<TestimonialOne/>

	<!-- Blog Section-->

	<!--<div class="blog-area gray-bg section-padding">
		<div class="container">
			<div class="row">
				<div class="col-lg-6 col-md-12 col-12">
					<div class="section-title">
						<h6>Blog</h6>
						<h2>Our Latest <b>Articles</b> </h2>
					</div>
				</div>

				<div class="col-lg-6 text-right">

				</div>
			</div>
			<div class="row">
				<div class="col-lg-4 col-md-6 col-sm-12 wow fadeInLeft" data-wow-delay=".4s" v-for="blog in blogs.slice(0,3)" :key="blog.id">
					<div class="single-blog-item">
						<div class="blog-bg">
							<img :src="blog.images.banner_image" :alt="blog.title">
						</div>
						<div class="blog-content">
							<p class="blog-meta"><i class="las la-user-circle"></i>{{blog.author}} | <i class="las la-calendar-check"></i>{{blog.date}}</p>
							<h5><router-link :to="{name:'BlogDetails', params:{ id: blog.id } }">{{blog.title}} </router-link>
							</h5>
							<p>{{blog.short_description.substr(0,60)}}....</p>
							<router-link :to="{name:'BlogDetails', params:{ id: blog.id } }" class="read-more">Read More</router-link>
						</div>

					</div>
				</div>


			
			</div>
		</div>
	</div>-->

</template>

<script>
import axios from 'axios'

// @ is an alias to /src
import NavbarHome1 from '@/components/NavbarHome1.vue'
import TestimonialOne from '@/components/TestimonialOne.vue'

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";
import { Navigation, Pagination, A11y, Autoplay } from "swiper";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";

export default {
    name: 'Home1',
    components : {NavbarHome1,  Swiper, SwiperSlide,TestimonialOne},
    data(){
        return {
            modules: [Navigation, Pagination, A11y, Autoplay],
            swiperOptions: {
                breakpoints: {
                354: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                },
                575: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                },
                767: {
                    slidesPerView: 2,
                    spaceBetween: 50,
                },

                990: {
                    slidesPerView: 3,
                    spaceBetween: 30,
                },
                1200: {
                    slidesPerView: 4,
                    spaceBetween: 30,
                },
                },
            },
			blogs : [],
			attorneys : [],
			choose_us : [],
			cases : [],
			services : []
        }
    },

	async mounted(){
        //scrolling to top of the window
        window.scrollTo(0, 0);
				
		let fetchedBlogData = await axios.get("/assets/data/blogs.json");
        this.blogs = fetchedBlogData.data.blogs;

		let fetchedAttorneyData = await axios.get("/assets/data/attorneys.json");
		this.attorneys = fetchedAttorneyData.data.attorneys;

		let fetchedData = await axios.get('/assets/data/choose_us.json');
		this.choose_us = fetchedData.data.choose_us;

		let fetchedCases = await axios.get('/assets/data/cases.json');
		this.cases = fetchedCases.data.cases;

		let fetcheServicesdData = await axios.get("/assets/data/services.json");
        this.services = fetcheServicesdData.data.services;

	}
}
</script>