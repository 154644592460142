<template>
    <NavbarHome1/>
    <Breadcrumb mainTitle="Contact" subTitle="Contact" />
    	<!-- Contact Area -->

	<div id="contact-us" class="contact-us-area section-padding">
		<div class="container">
			<div class="contact-us-wrapper">
				<div class="row no-gutters">
					<div class="col-lg-3 col-md-6 col-12">
						<div class="contact-us-inner">
							<div class="info-i"><span><i class="las la-map-marker"></i></span></div>
							<h5>Location</h5>
							<p>{{contactData.address}}</p>
							<a href="">Find us on map</a>
						</div>
					</div>
					<div class="col-lg-3 col-md-6 col-12">
						<div class="contact-us-inner">
							<div class="info-i"><span><i class="las la-clock"></i></span></div>
							<h5>Office Hours</h5>
							<p>{{contactData.opening_days}} <br>{{contactData.opening_hour}}</p>
							<a href="">Get Direction</a>
						</div>
					</div>
					<div class="col-lg-3 col-md-6 col-12">
						<div class="contact-us-inner">
							<div class="info-i"><span><i class="las la-mobile"></i></span></div>
							<h5>Phone Number</h5>
							<p>{{contactData.phone}} <br>{{contactData.secondary_phone}}</p>
							<a :href="'tel:' + contactData.phone">Call Now</a>
						</div>
					</div>
					<div class="col-lg-3 col-md-6 col-12">
						<div class="contact-us-inner">
							<div class="info-i"><span><i class="las la-envelope"></i></span></div>
							<h5>E-mail Address</h5>
							<p>{{contactData.mail}}<br>{{contactData.secondary_mail}}</p>
							<a :href="'mailto:' + contactData.mail">Email Us</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>


	<!-- Contact Form -->

	<div id="contact-page" class="contact-section blue-bg section-padding">
		<div class="container">
			<div class="row">
				<div class="col-lg-12 col-md-12 col-12 text-center wow fadeInRight">
					<div class="contact-form-wrapper">
						<div class="section-title" style="margin-bottom: 200px;">
    <h2>Get in <b>Touch</b></h2>
</div>
						<div class="evalution-form">
          <form action="form.php" method="post">
    <div class="row">
        <div class="col-lg-12 col-md-12 col-12">
            <input type="text" name="name" placeholder="Your Name">
        </div>
        <div class="col-lg-12 col-md-12 col-12">
            <input type="email" name="email" placeholder="Your E-mail">
        </div>
        <div class="col-lg-12 col-md-12 col-12">
            <input type="tel" name="phone" placeholder="Your Phone Number">
        </div>
        <div class="col-lg-12 col-md-12 col-12">
            <input type="text" name="subject" placeholder="Subject">
        </div>
        <div class="col-lg-12 col-md-12 col-12">
            <select name="service" required>
                <option value="" disabled selected>Select service required</option>
                <option value="General services">General Services</option>
                <option value="Complex Fraud">Complex Fraud</option>
                <option value="Personal Injury">Personal Injury</option>
                <option value="Civil Litigation">Civil Litigation</option>
                <option value="Medical Negligence">Medical Negligence</option>
                <option value="Sexual Offences">Sexual Offences</option>
                <option value="Criminal Law">Criminal Law</option>
                <option value="Motoring Offences">Motoring Offences</option>
                <option value="Motor Finance Claims">Motor Finance Claims</option>
                <option value="Immigration">Immigration</option>
            </select>
        </div>
        <div class="col-lg-12 col-md-12 col-12">
            <textarea name="message" cols="30" rows="10" placeholder="Your Message"></textarea>
        </div>
        <div class="col-lg-12 col-md-12 col-12">
            <input class="main-btn" type="submit" value="Submit Now">
        </div>
    </div>
</form>
        </div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<!--CTA Section-->

	<CTA/>

    
</template>

<script>
// @ is an alias to /src
import NavbarHome1 from '@/components/NavbarHome1.vue'
import Breadcrumb from '@/components/Breadcrumb.vue'
import CTA from '@/components/CTA.vue'

import axios from 'axios'
export default {
    name: 'Contact',
    components : {NavbarHome1,  Breadcrumb,CTA},
	data(){
		return {
			contactData : []
		}
	},

	async mounted(){
        //scrolling to top of the window
        window.scrollTo(0, 0);
				
		let fetchedData = await axios.get("/assets/data/homeData.json");
		this.contactData = fetchedData.data.homeData;
	}
}
</script>