<template>
    <NavbarHome1/>
    <Breadcrumb mainTitle="Registration" subTitle="Registration"/>

    <!-- Sign Up  -->

    <div class="main-wrapper gray-bg">
    <div class="image-holder">
        <img src="/assets/img/registration.jpg" alt="">
    </div>
    <div class="form-inner">
        <form action="">
            <div class="form-header">
                <h3>Sign Up</h3>
                <hr>
            </div>
            <div class="form-group">
                <label for="">Full Name</label>
                <input type="text" class="form-control">
            </div>
            <div class="form-group">
                <label for="">User Name</label>
                <input type="text" class="form-control">
            </div>
            <div class="form-group">
                <label for="">E-mail</label>
                <input type="email" class="form-control">
            </div>
            <div class="form-group">
                <label for="">Password</label>
                <input type="password" class="form-control">
            </div>
            <div class="form-group">
                <label for="">Select Country</label>
                <select name="" id="">
                    <option value="01">Australia</option>
                    <option value="01">United Kingdom</option>
                    <option value="01">Italy</option>
                    <option value="01">France</option>
                    <option value="01">Germany</option>
                </select>
            </div>
            <div class="form-group">
                <label for="">City</label>
                <input type="text" class="form-control">
            </div>
            
            <div class="form-group">
                <a href="#" class="main-btn">Create My Account</a>
            </div>
            
            <p class="form-meta">Already have an account?
                <a class="text-link" href="sign_in.html">Sign In</a>
                </p>                
        </form>
    </div>
    </div>                                                                   
    
</template>

<script>
// @ is an alias to /src
import NavbarHome1 from '@/components/NavbarHome1.vue'
import Breadcrumb from '@/components/Breadcrumb.vue'


export default {
    name: 'Registration',
    components : {NavbarHome1,  Breadcrumb},
    data(){
        return{
           

        }
    },
    mounted(){
        //scrolling to top of the window
        window.scrollTo(0, 0);
    }

    
}
</script>